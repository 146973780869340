@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

:root {
  --primary-white: #fff;
  --primary-orange: #f9ab00;
  --primary-dark: rgba(255, 255, 255, 0);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
}
body {
  font-family: "Roboto Condensed", sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* menu link */
nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}
.navbar-nav > li > a.active,
.navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}
.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

/*                      Header */
.header-wraper {
  position: relative;
  /* background: url(header-logo.jpg) no-repeat; */
  background: url(headerlogo.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}
.exprienceWraper {
  position: relative;
  /* background: url(header-logo.jpg) no-repeat; */
  background: url(exprince.webp) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}
.blog-wraper {
  position: relative;
  /* background: url(header-logo.jpg) no-repeat; */
  background: url(kms.webp) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.contact-wraper {
  position: relative;
  /* background: url(header-logo.jpg) no-repeat; */
  background: url(header-logo.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.skillsWrapper {
  position: relative;
  /* background: url(header-logo.jpg) no-repeat; */
  background: url(blue.webp) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
}

.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
}

/*  header main offer button */

.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}
.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var (--primary-white);
  transition: 0.2s ease-in-out;
}

.label-color {
  color: var(--primary-white);
}

.card {
  margin: 2rem;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.5);
  padding: 0.4rem;
}
.cardexprince {
  padding: 2rem;
  color: blue;
  padding-left: 5rem;
  padding-right: 5rem;
}
.cardskills {
  margin-bottom: 4rem;
  box-shadow: 0px 0px 10px 10px rgba(99, 83, 154, 0.46);
  padding: 1rem;
  border-radius: 1rem;
}

.cardAbout {
  box-shadow: 0px 0px 10px 10px rgba(99, 83, 154, 0.46);
  padding: 1rem;
  border-radius: 1rem;
  color: white;
  width: 18rem;
}
